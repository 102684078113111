// SearchBarNav.js
import React from 'react';
import MyDropdown from '../MyDropdown';
import { Button } from '../catalyst/button';
import { Field, Label } from '../catalyst/fieldset';
import { Input } from '../catalyst/input';
import { NavbarItem, NavbarSection } from '../catalyst/navbar';
import { Radio } from '../catalyst/radio';
import { MagnifyingGlassIcon } from '@heroicons/react/20/solid';
import * as Headless from '@headlessui/react'


function SearchBarNav ({
  serNum,
  modNum,
  manName,
  refineQuery,
  handleSearchSNChange,
  handleSearchMNChange,
  setManName,
  handleSearchRefineQueryChange,
  handleSearch,
  submissionError,
}) {

  return(
    <NavbarSection>
      <div className='flex flex-col items-center justify-center space-y-2'>
        <form onSubmit={handleSearch}>
          <div className='flex flex-row space-x-2'>
            <MyDropdown manName={manName} setManName={setManName} />
            <div className='flex flex-row items-center space-x-2'>
              <Field>
              <Input
                aria-label="Search Bar"
                name="serial_number"
                value={serNum}
                onChange={handleSearchSNChange}
                placeholder="Serial Number"
                className="min-w-80"
              />
              </Field>
              <Field>
                <Input
                  aria-label="Search Bar"
                  name="model_number"
                  value={modNum}
                  onChange={handleSearchMNChange}
                  placeholder="Enter Model"
                  className="min-w-80"
                />
              </Field>
            </div>
          </div>
          <div className='w-full text-xs text-center text-red-600 mb-0'>{submissionError}</div>
          <div className='flex flex-row w-full space-x-2'>
            <div className="flex items-center w-full">
              <Field className='w-full'>
                <Input
                  aria-label="Search Bar"
                  name="part_name"
                  value={refineQuery}
                  onChange={handleSearchRefineQueryChange}
                  placeholder="Part Description"
                  className="min-w-80"
                />
              </Field>
            </div>
            {/* <NavbarItem className='ml-2' aria-label="Search" type="submit" onClick={handleSearch}>
                <MagnifyingGlassIcon />
            </NavbarItem> */}
            <Button aria-label="Search" type="submit" onClick={handleSearch} className='bg-black w-1/4 max-h-10 mt-1'>
              Search
            </Button>
          </div>
        </form>
      </div>

    </NavbarSection>
  );
};

export default SearchBarNav;
