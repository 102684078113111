import React from 'react';
import { Link } from 'react-router-dom';

function Error404Page() {
    return (
        <div className="flex items-center justify-center min-h-screen text-black text-center">
        <div>
          <h1 className="text-9xl font-bold mb-4">404</h1>
          <p className="text-2xl mb-4">Page Not Found</p>
          <Link 
            to="/" 
            className="inline-block px-6 py-3 text-lg font-medium text-white bg-neutral-950 rounded-md hover:bg-neutral-600 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2"
          >
            Go back to Home
          </Link>
        </div>
      </div>
    );
};

export default Error404Page;