import React, { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

// Functions to help Breadcrumbs when clicked on "Results" to go back to the most
// recent results page in user's session

//PageTracker tracks naviation and stores the last /results/ page without "mismatch" in the URL
//in session storage
//Must wrap this component around <Router> in App.js in order to ensure PageTracker examines all routes
//being navigated to
export const PageTracker = ({ children }) => {
  const location = useLocation();

  useEffect(() => {
    const path = location.pathname;
    if (path.includes("results/man=") && !path.includes("mismatch")) {
      sessionStorage.setItem('lastResultsPage', path);
    }
  }, [location]);

  return children;
};

// Retrieves the URL stored in session storage and navigates to it
export const RedirectToLastResultsPage = () => {
  const navigate = useNavigate();

  useEffect(() => {
    const lastResultsPage = sessionStorage.getItem('lastResultsPage');
    if (lastResultsPage) {
      navigate(lastResultsPage, { replace: true });
    } else {
      navigate('/results/', { replace: true }); // Fallback if no previous "/results/" page found
    }
  }, [navigate]);

  return null; // This component doesn't render anything
};