// The card to show for manuals
import { Divider } from "../catalyst/divider";
import { Table, TableBody, TableCell, TableRow } from '../catalyst/table';

function SearchResultEntry({tableRowNum, manualPath, manualFileName}) {
  function openLink(manualPath) {
    window.open(`https://www.partstown.com/modelManual/${manualPath}`, '_blank');
  };

  function detManualName(manualFileName, manualPath) {
    if (manualPath.includes("iom.pdf")) {
      return `${manualFileName} Installation & Operation Manual`
    } else if (manualPath.includes("im.pdf")) {
      return `${manualFileName} Installation Manual`
    } else if (manualPath.includes("om.pdf")) {
      return `${manualFileName} Operation Manual`
    } else if (manualPath.includes("spm.pdf")) {
      return `${manualFileName} Service Manual`
    } else if (manualPath.includes("pm.pdf")) {
      return `${manualFileName} Parts Manual`
    } else if (manualPath.includes("wd.pdf")) {
      return `${manualFileName} Wiring Diagram Manual`
    } else {
      return `${manualPath} Manual`
    }
  }

  return (
    // search-result-entry
    <TableRow key={tableRowNum}>
      <TableCell className='flex flex-row cursor-pointer select-none' onClick={() => {openLink(manualPath)}}>
        <div className="flex items-center">
          <svg className="w-10 h-6 invert" xmlns="http://www.w3.org/2000/svg" fill="white" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor">
            <path d="M12 6.042A8.967 8.967 0 0 0 6 3.75c-1.052 0-2.062.18-3 .512v14.25A8.987 8.987 0 0 1 6 18c2.305 0 4.408.867 6 2.292m0-14.25a8.966 8.966 0 0 1 6-2.292c1.052 0 2.062.18 3 .512v14.25A8.987 8.987 0 0 0 18 18a8.967 8.967 0 0 0-6 2.292m0-14.25v14.25" />
          </svg>
        </div>
        <div className="text-left text-inherit text-sm font-semibold">
          <p>
            {detManualName(manualFileName, manualPath)}
          </p>
          <p className="text-neutral-500">
            {manualPath}
          </p>
        </div>
      </TableCell>
    </TableRow>


    // <div className="items-center text-center w-full">
    //   <button
    //   className= "hover:bg-gray-100 font-bold p-2.5 w-3/4 inline-flex rounded-lg border border-gray-200 dark:border-gray-800 items-center"
    //   onClick={() => {openLink(manualPath)}}>
    //     {/* <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="size-6 mr-3">
    //       <path strokeLinecap="round" strokeLinejoin="round" d="M12 6.042A8.967 8.967 0 0 0 6 3.75c-1.052 0-2.062.18-3 .512v14.25A8.987 8.987 0 0 1 6 18c2.305 0 4.408.867 6 2.292m0-14.25a8.966 8.966 0 0 1 6-2.292c1.052 0 2.062.18 3 .512v14.25A8.987 8.987 0 0 0 18 18a8.967 8.967 0 0 0-6 2.292m0-14.25v14.25" />
    //     </svg> */}
    //     <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-10 h-6 mr-3">
    //       <path d="M12 6.042A8.967 8.967 0 0 0 6 3.75c-1.052 0-2.062.18-3 .512v14.25A8.987 8.987 0 0 1 6 18c2.305 0 4.408.867 6 2.292m0-14.25a8.966 8.966 0 0 1 6-2.292c1.052 0 2.062.18 3 .512v14.25A8.987 8.987 0 0 0 18 18a8.967 8.967 0 0 0-6 2.292m0-14.25v14.25" />
    //     </svg>
    //     <div className="text-left text-inherit text-sm">
    //       <p>
    //         {detManualName(manualFileName, manualPath)}
    //       </p>
    //       <p className="text-neutral-500">
    //         {manualPath}
    //       </p>
    //     </div>
    //   </button>
    //   <Divider className="my-1" />
    // </div>
  );
};

export default SearchResultEntry;