// Breadcrumbs.js
import React from 'react';
import { Link, useLocation } from 'react-router-dom';

function Breadcrumbs() {
    const location = useLocation();

    //Obtains breadcrumbs from the URL
    let paths = location.pathname.split('/').filter((path) => path);

    //Since this gets pages from the URL via '/', it will also pick up the parameters passed in the URL
    //such as man=, mn_sn=, and query=
    //Removes those query parameters from the breadcrumbs
    paths = paths.filter((path) => !path.includes('='));


    //Capitalizes each word in the path
    paths.forEach((word, index) => {
        const firstLetter = word.charAt(0).toUpperCase();
        const rest = word.slice(1).toLowerCase();

        paths[index] = firstLetter + rest;
    });
    

    //Doesn't show breadcrumbs if there is only one path (i.e. the home page)
    if (paths.length === 0) {
        return null;
    }

    //Replaces URL term with more appropriate term if needed
    const replaceTerms = { "Mismatch": "Can't Find Part" };

    return (
        <nav className="text-gray-500 w-full dark:text-white" aria-label="breadcrumb">
            <ol className="list-none p-0 inline-flex">
            <li className="flex items-center">
                <Link to="/">Home</Link>
                {paths.length > 0 && <span className="mx-2">/</span>}
            </li>
            {paths.map((path, index) => {
                const to = `/${paths.slice(0, index + 1).join('/')}`;
                const isLast = index === paths.length - 1;
                const displayText = replaceTerms[path] || path; //Replaces the URL term with a more appropriate term if needed
                console.log(path, displayText);
                return (
                <li key={to} className="flex items-center">
                    {!isLast ? (
                    <>
                        <Link to={to}>{displayText}</Link>
                        <span className="mx-2">/</span>
                    </>
                    ) : (
                    <span className="capitalize">{displayText}</span>
                    )}
                </li>
                );
            })}
            </ol>
        </nav>
    );
};

export default Breadcrumbs;
