import React, { useContext } from 'react';
import { Link } from 'react-router-dom'
import { useLocation } from 'react-router-dom';
import { Navbar, NavbarDivider, NavbarSpacer, NavbarItem } from './catalyst/navbar'
import { Dropdown, DropdownButton, DropdownMenu, DropdownItem, DropdownLabel, DropdownDivider } from './catalyst/dropdown'
import { Avatar } from './catalyst/avatar'
import { UserIcon, Cog8ToothIcon, ShieldCheckIcon, LightBulbIcon, ArrowRightStartOnRectangleIcon } from '@heroicons/react/20/solid';
import logo from "../assets/logo.png"
import SearchBarNav from './searchbars/SearchBarNav'
import SearchLogic from './searchbars/SearchLogic'
import { useAuth0 } from "@auth0/auth0-react";

function MyNavbar() {
    const location = useLocation();
    const { user, logout } = useAuth0();

    return(
        <Navbar>

          <Link to="/" aria-label="Home">
            <img src={logo} alt="" className='w-72'></img>
          </Link>

          <NavbarSpacer />

          { location.pathname !== '/' &&
            <SearchLogic>
              {props => <SearchBarNav {...props} />}
            </SearchLogic>
          }

          <NavbarSpacer />

          <NavbarDivider />
          <NavbarItem aria-label="Support" href="/support">Support</NavbarItem>
          <NavbarDivider />
          <Dropdown>
            <DropdownButton as={NavbarItem} aria-label="Account menu">
              <Avatar src={user ? user.picture : ""} square />
            </DropdownButton>
            <DropdownMenu className="min-w-64" anchor="bottom end">
              {/* <DropdownItem href="/my-profile">
                <UserIcon />
                <DropdownLabel>My profile</DropdownLabel>
              </DropdownItem>
              <DropdownItem href="/settings">
                <Cog8ToothIcon />
                <DropdownLabel>Settings</DropdownLabel>
              </DropdownItem>
              <DropdownDivider />
              <DropdownItem href="/privacy-policy">
                <ShieldCheckIcon />
                <DropdownLabel>Privacy policy</DropdownLabel>
              </DropdownItem>
              <DropdownItem href="/share-feedback">
                <LightBulbIcon />
                <DropdownLabel>Share feedback</DropdownLabel>
              </DropdownItem>
              <DropdownDivider /> */}
              <DropdownItem onClick={() => logout({ logoutParams: { returnTo: window.location.origin } })}>
                <ArrowRightStartOnRectangleIcon />
                <DropdownLabel>Sign out</DropdownLabel>
              </DropdownItem>
            </DropdownMenu>
          </Dropdown>
          
        </Navbar>
    )
};

export default MyNavbar;