// The card to show for Bloomfire API results
import { useEffect, useState } from 'react';
import { ChevronLeft, ChevronRight } from 'lucide-react';

function SearchResultBFEntry({
  tableRowNum, //the row number in the table (aka key)
  name = "", //Is a string
  description = [], //Is the description of the post
  post_content = [], //Is the post content
  doc_body = [], //Is an array of all the text snippets Bloomfire thought was accurate
  postID = -1, //Is the post ID
  query="", //Is the search query
  url="", //Is the URL of the post
  lastUpdated = "", //Is the last updated date of the post
  author = {"first_name": "", "last_name": ""} //Is the author of the post
}) {

// Checks that name is a string and description is a list of strings (even though it'll be 1 at most)
  // highlight field passes name in as an array with one item, so it make sure to convert
  // the name into a string
  if (Array.isArray(name)) {
    name = name[0]
  }

  // instance field passes description in as a string
  // Because BF treats description as a list of results, we will do the same
  // To do so, we treat description as an array of findings (i.e. one item array)
  if (description === " " || description === "") {
    description = [];
  };
  description = (typeof description === 'string') ? [description] : description;


  // Puts the excerpt arrays (description, post_content, doc_body) into a dictionary
  // Key value is the title of the array, the value is the array itself
  // Only the ones that actually have content (i.e. filters out the ones with a length of 0)
  const totalArr = {"Post Description": description, "Relevant Lines from post": post_content, "Relevant Lines from PDF": doc_body};
  const exArrs = Object.fromEntries(
    Object.entries(totalArr).filter(([key, value]) => Array.isArray(value) && value.length > 0)
  );

  // Which of the excerpt arrays inside exArrs to show (will have any combination of description, post_content, or doc_body)
  const [currExArrNum, setCurrExArrNum] = useState(0);

  // The name of the current excerpt array within exArrs to show (aka the key value)
  const currExArrName = Object.keys(exArrs)[currExArrNum];
  // The current excerpt array within exArrs to show
  const currExArr = exArrs[currExArrName];

  //Changes the lastUpdatedString to a more readable format
  //(e.g. 2022-05-12T19:07:44.842Z to May 12, 2022)
  function changeLastUpdatedString(lastUpdatedString) {
    if (!lastUpdatedString) {
      return "Unknown";
    }
    const date = new Date(lastUpdatedString);
    return date.toLocaleDateString('en-US', { year: 'numeric', month: 'long', day: 'numeric' });
  }


  //Maps each item in the document body or post_content
  function mapPostItems(list_item, index, query) {
    //Function to highlight identified word in a string of text
    function highlightKeyWord(text, word) {
      //Splits the word into a list in case the query was multiple words
      word = word.split(" ")

      //Checks whether we have a query to search
      if (!word || word.length === 0) {
        return <span>{text}</span>;
      }
    
      // Join the keywords array into a single regular expression pattern
      const regex = new RegExp(`(${word.join('|')})`, 'gi');
    
      // Split the text into parts that match and don't match the regex
      const parts = text.split(regex);
    
      return (
        <span className="text-xs">
          {parts.map((part, index) =>
            regex.test(part) ? (
              <span key={index} style={{ backgroundColor: 'yellow' }}>
                {part}
              </span>
            ) : (
              part
            )
          )}
        </span>
      );
    }
    
    return (
      <div key={index} className="flex items-start text-inherit">
        <span className="inline-block text-right text-sm">{index + 1}.</span>
        <span className="flex-1 pl-2 leading-tight">"...{highlightKeyWord(list_item, query)}..."</span>
      </div>
    )
  }

  //Returns a div of descriptions, post_contents, or doc_body's
  function returnPostItems(postItemList, postItemListTitle, query) {
    if (postItemList.length !== 0) {
      return(
        <div className="w-full">
          <p className="text-inherit text-[14.5px] font-bold pt-2 pb-1">{postItemListTitle}:</p>
          {postItemList.map((list_item, index) => (mapPostItems(list_item, index, query)))}
        </div>
      )
    }
  }

  const exArrsMaxInd = ((Object.keys(exArrs)).length) - 1; //Maximum index number in exArrs (e.g. how far you can increase currArrNum)

  //Ensures that the current excerpt array number is within the bounds of the number of arrays available
  useEffect(() => {
    if (currExArrNum > exArrsMaxInd) {
      setCurrExArrNum(exArrsMaxInd);
    }
  }, [currExArrNum, exArrsMaxInd]);


  return (
    // search-result-entry
    <div className="flex flex-col items-center w-full text-inherit">
      <div className="pb-4 mb-2 max-w-md w-full grid grid-cols-1 rounded-lg border border-gray-200 dark:border-gray-800">
        <div className="mt-4 mx-4 cursor-pointer" onClick={() => window.open(`${url}`, '_blank')}>
          <h3 className="text-md font-semibold text-left">{name}</h3>
          <p className="text-sm text-gray-600">
            {author.first_name} {author.last_name}
          </p>
          <p className="text-xs text-gray-500 mb-2">Updated {changeLastUpdatedString(lastUpdated)}</p>
        </div>

       {currExArr &&
       <div className="flex w-full">
          <div className="flex flex-col justify-center items-center w-14 h-full cursor-pointer" onClick={() => setCurrExArrNum(prevCurrExArrNum => (prevCurrExArrNum > 0 ? prevCurrExArrNum - 1 : prevCurrExArrNum))}>
            <ChevronLeft className={`min-w-6 min-h-6 ${currExArrNum <= 0 ? "opacity-30" : ""}`}/>
          </div>

          {/* Will return one of the three excerpt arrays depending on what the current excerpt array number is */}
          {/* Passes in the excerpt array (description, post_content, doc_body), the name of the array, and the query */}
          {returnPostItems(currExArr, currExArrName, query)}

          <div className="flex flex-col justify-center h-full w-14 cursor-pointer" onClick={() => setCurrExArrNum(prevCurrExArrNum => (prevCurrExArrNum < exArrsMaxInd ? prevCurrExArrNum + 1 : prevCurrExArrNum))}>
            <ChevronRight className={`min-w-6 min-h-6 ${currExArrNum >= exArrsMaxInd ? "opacity-30" : ""}`}/>
          </div>
          
        </div>}

      </div>
    </div>
  );
};

export default SearchResultBFEntry;