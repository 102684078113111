// Loading Component to show while the API is fetching
import React from 'react';
import instalily_mark_white_4x from "../assets/instalily_mark_white_4x.png"

function Loading() {
    return (
        <div className="flex flex-col items-center h-screen">
            <div className='flex flex-row items-center mt-10'>
                <p className='font-extrabold text-3xl text-neutral-950'>
                    Loading...
                </p>
                <img src={instalily_mark_white_4x} alt="" className='invert size-10 ml-5 spin'></img>
            </div>
        </div>
    );
};

export default Loading;