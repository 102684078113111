import React, { useState, useEffect, useContext } from 'react';
import { AppContext } from '../AppContext';

import { Field, FieldGroup, Fieldset, Label } from './catalyst/fieldset';
import { Input } from './catalyst/input';
import { Button } from './catalyst/button';
import { Textarea } from './catalyst/textarea';
import { Select } from './catalyst/select';
import { Divider } from './catalyst/divider';

import { useParams, useNavigate } from 'react-router-dom';



function SupportPage() {
    const { manList } = useContext(AppContext);
    const { urlMfgName, urlSN, urlMN, urlRQ } = useParams();


    // Cleans up the passed in parameters with the split function since 
    // man=, sn=, mn=, and refine= are passed in the URL as well
    const urlMfgNameProcessed = urlMfgName ? decodeURIComponent(urlMfgName.split("=")[1]) : '';
    const urlSNProcessed = urlSN ? decodeURIComponent(urlSN.split("=")[1]) : '';
    const urlMNProcessed = urlMN ? decodeURIComponent(urlMN.split("=")[1]) : '';
    const urlRQProcessed = urlRQ ? decodeURIComponent(urlRQ.split("=")[1]) : '';
    

    useEffect(() => {
        console.log("SupportPage useEffect reporting: ", urlMfgNameProcessed, urlSNProcessed, urlMNProcessed, urlRQProcessed);
    }, [urlMfgNameProcessed, urlSNProcessed, urlMNProcessed, urlRQProcessed]);


    const navigate = useNavigate();

    const [formValues, setFormValues] = useState({
        manName: '',
        typeOfUnit: '',
        modelNum: '',
        serialNum: '',
        partsNeeded: '',
        accountNum: '',
        cellNum: '',
        email: '',
        prefContact: '',
    });

    const handleChange = (event) => {
        const { name, value } = event.target;
        setFormValues({
            ...formValues,
            [name]: value,
        });
    };

    // Initialize the form fields with the URL parameters
    useEffect(() => {
        console.log("SupportPage useEffect: ", urlMfgNameProcessed, urlSNProcessed, urlMNProcessed, urlRQProcessed);
        setFormValues(prevValues => ({
            ...prevValues,
            manName: (manList.includes(urlMfgNameProcessed) ? urlMfgNameProcessed : ''),
            modelNum: urlMNProcessed ? urlMNProcessed : '',
            serialNum: urlSNProcessed ? urlSNProcessed : '',
        }));
    }, [urlMfgNameProcessed, urlSNProcessed, urlMNProcessed, urlRQProcessed, manList]);



    // Error Checking
    const [errors, setErrors] = useState({});
    function validate() {
        let newErrors = {};

        if (!formValues.manName || formValues.manName === 'Select Manufacturer') {
            newErrors.manName = 'Manufacturer is required';
        }
        if (!formValues.typeOfUnit) {
            newErrors.typeOfUnit = 'Type of Unit is required';
        }
        if (!formValues.modelNum) {
            newErrors.modelNum = 'Model Number is required';
        }
        if (!formValues.serialNum) {
            newErrors.serialNum = 'Serial Number is required';
        }
        if (!formValues.partsNeeded) {
            newErrors.partsNeeded = 'Parts Needed is required';
        }
        if (!formValues.accountNum) {
            newErrors.accountNum = 'Account Number is required';
        }
        if (!formValues.cellNum) {
            newErrors.cellNum = 'Cell Phone Number is required';
        }
        if (!formValues.email) {
            newErrors.email = 'Email Address is required';
        } else if (!/\S+@\S+\.\S+/.test(formValues.email)) {
            newErrors.email = 'Email Address is invalid';
        }
        if (!formValues.prefContact) {
            newErrors.prefContact = 'Preferred Contact Option is required';
        }

        setErrors(newErrors);
        return Object.keys(newErrors).length === 0;
    };


    function handleSubmit(event) {
        event.preventDefault();
        if (validate()) {
            // Handle form submission logic here
            console.log("Support form submitted: ", formValues);
            // Navigate to the /submitted route and pass the form values in the state
            navigate('/submitted', { state: formValues });
        } else {
            console.log("Validation failed.");
        }
    };



    return (
        <div className="min-h-screen flex flex-col items-center w-full">
            <div className='w-10/12'>
                <h1 className='text-3xl font-bold mb-4 dark:text-white'>Can't Find{urlSNProcessed && ` Serial Number "${urlSNProcessed}"`}{(urlSNProcessed && urlMNProcessed ? " and" : "")}{urlMNProcessed && ` Model Number "${urlMNProcessed}"`}</h1>
            </div>
            <Divider />
            <div className='w-96'>
                <form action="/orders" method="POST" onSubmit={handleSubmit}>
                    <Fieldset>
                        <FieldGroup>
                            <Field>
                                <Label>Manufacturer</Label>
                                <Select name="manName" value={formValues.manName} onChange={handleChange}>
                                    {manList.map((item, index) => (<option key={index} value={item}>{item}</option>))}
                                </Select>
                                {errors.manName && <span className="text-red-500">{errors.manName}</span>}
                            </Field>
                            <Field>
                                <Label>Type of Unit</Label>
                                <Input name="typeOfUnit" value={formValues.typeOfUnit} onChange={handleChange}/>
                                {errors.typeOfUnit && <span className="text-red-500">{errors.typeOfUnit}</span>}
                            </Field>
                            <Field>
                                <Label>Model Number</Label>
                                <Input name="modelNum" value={formValues.modelNum} onChange={handleChange}/>
                                {errors.modelNum && <span className="text-red-500">{errors.modelNum}</span>}
                            </Field>
                            <Field>
                                <Label>Serial Number</Label>
                                <Input name="serialNum" value={formValues.serialNum} onChange={handleChange}/>
                                {errors.serialNum && <span className="text-red-500">{errors.serialNum}</span>}
                            </Field>
                            <Field>
                                <Label>Parts Needed</Label>
                                <Textarea name="partsNeeded" value={formValues.partsNeeded} onChange={handleChange}/>
                                {errors.partsNeeded && <span className="text-red-500">{errors.partsNeeded}</span>}
                            </Field>
                            <Field>
                                <Label>Account Number</Label>
                                <Input name="accountNum" value={formValues.accountNum} onChange={handleChange}/>
                                {errors.accountNum && <span className="text-red-500">{errors.accountNum}</span>}
                            </Field>
                            <Field>
                                <Label>Cell Phone Number</Label>
                                <Input name="cellNum" value={formValues.cellNum} onChange={handleChange}/>
                                {errors.cellNum && <span className="text-red-500">{errors.cellNum}</span>}
                            </Field>
                            <Field>
                                <Label>Email Address</Label>
                                <Input name="email" value={formValues.email} onChange={handleChange}/>
                                {errors.email && <span className="text-red-500">{errors.email}</span>}
                            </Field>
                            <Field>
                                <Select name="prefContact" value={formValues.prefContact} onChange={handleChange}>
                                    <option value="">Preferred Contact Option</option>
                                    <option value="cellphone">Cell Phone</option>
                                    <option value="email">Email</option>
                                </Select>
                                {errors.prefContact && <span className="text-red-500">{errors.prefContact}</span>}
                            </Field>
                        </FieldGroup>
                    </Fieldset>
                    <Button type='submit' className='w-full mt-10 h-full'>Submit</Button>
                </form>
            </div>
        </div>
    );
}


export default SupportPage;