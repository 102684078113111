import React from 'react';

import { Divider } from './catalyst/divider';

import SearchBarHome from './searchbars/SearchBarHome'
import SearchLogic from './searchbars/SearchLogic'

function HomePage() {
    return (
        <div className='h-[70vh] w-full flex items-center justify-center'>
            <div className="flex items-center justify-center w-full">
                <div className="flex flex-col justify-center p-8 rounded-sm shadow-sm w-3/5 my-auto">
                    <h2 className="text-center text-2xl font-bold dark:text-white">Search for a Part</h2>
                    <Divider className='my-4'/>
                    <SearchLogic>
                        {props => <SearchBarHome {...props} />}
                    </SearchLogic>
                </div>
            </div>
        </div>
    );
}


export default HomePage;