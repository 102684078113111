import React, { useContext } from 'react';
import { AppContext } from '../AppContext';
import { Field } from './catalyst/fieldset';
import { Select } from './catalyst/select'


function MyDropdown({ manName, setManName }) {
    const { manList } = useContext(AppContext);

    const handleChange = (event) => {
        if (event.target.value === "Manufacturer") {
            setManName("");
        } else {
            setManName(event.target.value);
        }
    };

    return (
        <Field className='w-auto'>
            <Select name="manName" value={manName} onChange={handleChange}>
                {manList.map((item, index) => (<option key={index} value={item}>{item}</option>))}
            </Select>
        </Field>
    );
};

export default MyDropdown;
